<template>

	<div>
		<!-- 公共头部 -->
		<Header :title="title"></Header>
		
		<div class="video-box">
				<!-- <Banner></Banner> -->
			<!-- <div class="cate-title">公益视频</div> -->
			<div class="catebox">
				<div class="cateitem" v-for="(item,index) in cateList" :class="[ id == item.id? 'activecate':'' ]" :key="index" :data-id="item.id"  @click="toggleCont">
					{{item.title}}
				</div>
			</div>
			<ul class="list clearfix" v-if="newsList.length>0">
				<li class="item fl" v-for="(item,index) in newsList" :key="index">
					<div class="pic-box" :style="{backgroundImage: 'url(' + item.img +')'}" @click="openMask(index)">
						<img class="icon" src="@/assets/images/sp.png" />
					</div>
						<div class="title">{{item.title}}</div>
					<div class="video_title">
					<div class="view">{{item.num}}</div>
					<div class="time">{{item.addtime}}</div>
					</div>
					
				</li>
			</ul>
			<div class="nolist" v-if="newsList.length<=0">
			<div class="tost_box">
				<div class="img"><img :src="nolist" alt=""></div>
				<div class="text">暂无相关视频</div>
			</div>
			</div>
			<!-- <el-pagination
				background
				:page-size="limit"
				:current-page.sync="page"
				:pager-count="7"
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange">
			</el-pagination> -->
		</div>
		<!-- 视频弹出层 -->
		<div class="mask" v-if="showMask"></div>
		<div class="alert-box" :class="[ showMask ? 'active' : '' ]">
			<div class="title">{{videoTitle}}
				<img class="close" @click="closeMask" src="@/assets/images/menu_close.png" />
			</div>
			<div class="alert-video-box" v-if="showMask">
				<video-player class="video-player vjs-custom-skin"
					ref="videoPlayer"
					:playsinline="true"
					:oncontextmenu ="false"
					@loadeddata="onPlayerLoadeddata"
					controlslist="nodownload"
					:options="playerOptions">
				</video-player>
			</div>
		</div>
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import { videoPlayer } from 'vue-video-player';
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	import nolist from '@/assets/images/icon106.png';
		import Banner from '@/components/Index/components/Banner.vue';
	export default {
		components: {
			videoPlayer,
			Header,
			Footer,
			Banner
		},
		 beforeRouteLeave(to,from,next){
			 this.showMask = false
            next();//不跳转
        
		 },

		data() {
			return {
				nolist:nolist,
				title:"",
				id:'',
				page: 1,
				limit: 9,
				total: 0,
				newsList: [],
				showMask: false,
				cateList:[],
				curIndex: 0,
				videoTitle: '',
				
				playerOptions: {
					
					playbackRates: [1.0], // 可选的播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{}],
					poster: '', // 封面地址
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: false, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					}
				}
			}
		},
		mounted() {
		
		},
		watch: {
			$route(to,form) {
				if((to.path == form.path) && (this.$route.path == '/video')){
					if(this.page == 1){
						this.getNewsList();
					} else {
						this.page = 1;
					}
				}
			},
			page() {
				if(this.$route.path == '/video'){
					this.getNewsList();
				}
			}
		},
		activated() {
		
			this.getCateList()
		},
		methods: {
				toggleCont(e){
			
				this.page = 1;
				this.limit = 10;
				let id = e.target.dataset.id||this.$route.query.id;
				this.id = id;
				let type = e.target.dataset.type||this.$route.query.type;
				this.type = type;
				this.getNewsList()

			},
				async getCateList() {
			
				const { data:res } = await this.$http.get('/gongyi/cate');
				
				if(this.$store.state.beta){
					console.log('公益视频公用：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					
					let list = res.data;
			
					list.forEach(item => {
						if (item.title=='首页视频') {
							list.splice(list.indexOf(item))
						}
					});
					this.cateList = res.data;
					console.log(this.cateList,'list');
					this.id = this.cateList[0].id
					this.getNewsList();
				} else {
					this.cateList = [];
				}
			},
			// 增加视频播放量

			async addnum(){
				var id = this.newsList[this.curIndex]['id'];
				var data = {
					id
				}
				const { data:res } = await this.$http.get('/gongyi/addnum', { params: data });
				if(this.$store.state.beta){
					console.log('列表页增加视频播放量：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.newsList[this.curIndex]['num'] = Number(this.newsList[this.curIndex]['num']) + 1;
				}
			},
			onPlayerLoadeddata(player){
				if(this.$store.state.beta){
					console.log('列表页弹窗视频加载完成：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds());
				}
				player.play();
				console.log(this.$refs.videoPlayer);
				// this.$refs.videoPlayer.$attrs('controlslist','nodownload')
				this.addnum();
			},
			// 打开视频弹出层
			openMask(index) {
				var newsList = this.newsList;
				this.curIndex = index;
				this.videoTitle = newsList[index]['title'];
				this.playerOptions.poster = newsList[index]['img'];
				this.playerOptions.sources[0]['src'] = newsList[index]['video'];
				this.showMask = true;
				
			},
			// 关闭视频
			closeMask() {
				this.showMask = false;
				this.$refs.videoPlayer.player.pause(); // 暂停
			},
			handleCurrentChange(page) {
				this.page = page;
			},
			async getNewsList() {
			
				var cateId = this.id || 0;
				var page = this.page;
				var limit = this.limit;
				var data = {
					cateId, page, limit
				}
			
				const { data:res } = await this.$http.get('/gongyi/lists', { params: data });
				console.log(res);
				// loading.close();
				if(this.$store.state.beta){
					console.log('视频列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.total = res.data.count;
					var newsList = res.data.lists;
					for(var i = 0; i < newsList.length; i++) {
						if(newsList[i]['img'] && newsList[i]['img'].substring(0,4) != 'https'){
							newsList[i]['img'] = this.$store.state.http + newsList[i]['img'];
							newsList[i].addtime = newsList[i].addtime.slice(0,10)
						}
						if(newsList[i]['video'] && newsList[i]['video'].substring(0,4) != 'http'){
							newsList[i]['video'] = this.$store.state.http + newsList[i]['video'];
						}
					}
					this.newsList = newsList;
				} else {
					this.total = 0;
					this.newsList = [];
				}
			}
		}
	}
</script>

<style scoped>
	.video-box{
		padding-bottom: 50px;
	}

video::-internal-media-controls-download-button {display:none;}
video::-webkit-media-controls-enclosure {overflow:hidden;}
video::-webkit-media-controls-panel { width: calc(100% + 30px); }
	.video-box .cate-title{
		text-align: center;
		height: 60px;
		line-height: 60px;
		background: #e6e6e6;
		font-size: 18px;
		color: #333333;
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;
		padding: 0 30px;
	}
	.video-box .list{
		/* margin-top: 40px; */
	}
	.video-box .list .item{
		width: 43vw;
		margin:0 3vw;
		padding-bottom: 20px;
		margin-top: 20px;
	}
	/* .video-box .list .item:nth-child(1),
	.video-box .list .item:nth-child(2),
	.video-box .list .item:nth-child(3){
		margin-top: 0;
	}
	.video-box .list .item:nth-child(3n){
		margin-right: 0;
	} */
	.video-box .list .item .pic-box{
		display: block;
		width: 100%;
		height: 180px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		margin-bottom: 25px;
		cursor: pointer;
	}
	.video-box .list .item .pic-box .icon{
		border-radius: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-webkit-transform: translate(-50%,-50%);
		box-shadow: 0 8px 8px rgba(0,0,0,.3);
	}
	.video-box .list .item .title{
		font-size: 1em;
		color: #333333;
		line-height: 25px;
		font-weight: lighter;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.video-box .list .item .view{
		
		font-size: 14px;
		color: #7c7c7c;
		padding-left: 32px;
		background: url(../../assets/images/icon15.png) no-repeat left center;
		background-size: auto;

	}
	
	.alert-box{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0);
		-ms-transform: translate(-50%,-50%) scale(0);
		-o-transform: translate(-50%,-50%) scale(0);
		-moz-transform: translate(-50%,-50%) scale(0);
		-webkit-transform: translate(-50%,-50%) scale(0);
		z-index: 999;
		width: 100%;
		background: #222222;
		transition: all .3s;
	}
	.alert-box.active{
		transform: translate(-50%,-50%) scale(1);
		-ms-transform: translate(-50%,-50%) scale(1);
		-o-transform: translate(-50%,-50%) scale(1);
		-moz-transform: translate(-50%,-50%) scale(1);
		-webkit-transform: translate(-50%,-50%) scale(1);
	}
	.alert-box .title{
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		height: 50px;
		line-height: 50px;
		position: relative;
	}
	.alert-box .title .close{
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 999;
		cursor: pointer;
		width: 10px;
		height: 10px;
		filter: brightness(0);
		filter: invert(1);
	}
	.alert-box .alert-video-box{
		padding: 0 15px 15px;
	}
	.video_title{
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: space-around;
		margin-top: 15px;
	}
	.time{
			font-size: 14px;
		color: #7c7c7c;
	}
	.catebox{
		height:.8rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: .28rem;
		flex-wrap: nowrap;
		
		box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
	}
	.cateitem{
		height: 100%;
		line-height: .8rem;
		text-align: center;
		flex: 1;
	}
	.activecate{
			font-size: 0.3rem;
		color: #FFFFFF;
		background: #86cb08;
	}
	.nolist{
		width: 80%;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	.tost_box{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #ccc;
	}
		.tost_box img{
			width: 2rem;
			object-fit: contain;
		}
</style>
